import React from 'react';    
import './Banner.css';

export default function Banner(props){
    
    return (    
    <header role="banner" className='banner'>
          <h1>Ouch, My Everything!</h1>
          <h2>An Online Pain Journal</h2>
    </header>
    )
}